.main-container {
  width: 100%;
  height: 100%;
  z-index: -1;
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.constellation {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.star {
  background-color: #fff;
  border-radius: 50%;
  animation-name: star;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
}

.star.style1 {
  animation-name: star;
  animation-duration: 2s;
}

.star.style2 {
  animation-name: star;
  animation-duration: 2.5s;
}

.star.style3 {
  animation-name: star;
  animation-duration: 3s;
}

.star.style4 {
  animation-name: starHighlighted;
  animation-duration: 3.5s;
}

.star.size1 {
  width: 1px;
  height: 1px;
}

.star.size2 {
  width: 2px;
  height: 2px;
}

.star.size3 {
  width: 3px;
  height: 3px;
}

.star.opacity1 {
  opacity: 1;
}

.star.opacity2 {
  opacity: .5;
}

.star.opacity3 {
  opacity: .1;
}

.meteor {
  z-index: 10;
  width: 3px;
  height: 3px;
  background: linear-gradient(to top, #fff0, #ffffffbf);
  border-top-left-radius: 20%;
  border-top-right-radius: 50%;
  animation-duration: 6s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes animShootingStar {
  from {
    opacity: 1;
    height: 1%;
    transform: translateY(0)translateX(0)rotate(115deg);
  }

  to {
    opacity: 1;
    height: 30%;
    transform: translateY(1260px)translateX(2560px)rotate(115deg);
  }
}

.meteor.style1 {
  animation-name: meteorStyle1;
}

.meteor.style2 {
  animation-name: meteorStyle2;
}

.meteor.style3 {
  animation-name: meteorStyle3;
}

.meteor.style4 {
  animation-name: meteorStyle4;
}

@keyframes darken {
  0% {
    top: 0;
  }

  100% {
    top: 100%;
  }
}

@keyframes star {
}

@keyframes starHighlighted {
  0% {
    opacity: .1;
    background-color: #fff;
  }

  20% {
    opacity: 1;
    background-color: #fff;
  }

  80% {
    opacity: 1;
    background-color: #fff;
  }

  100% {
    opacity: 0;
    background-color: #fff;
  }
}

@keyframes meteorStyle1 {
  from {
    opacity: 1;
    height: 1%;
    transform: translateY(0)translateX(0)rotate(125deg);
  }

  to {
    opacity: 1;
    height: 20%;
    transform: translateY(2560px)translateX(2560px)rotate(125deg);
  }
}

@keyframes meteorStyle2 {
  from {
    opacity: 1;
    height: 1%;
    transform: translateY(200px)translateX(300px)rotate(105deg);
  }

  to {
    opacity: 1;
    height: 20%;
    transform: translateY(900px)translateX(2560px)rotate(105deg);
  }
}

@keyframes meteorStyle3 {
  from {
    opacity: 1;
    height: 1%;
    transform: translateY(100px)translateX(200px)rotate(135deg);
  }

  to {
    opacity: 1;
    height: 15%;
    transform: translateY(2560px)translateX(2560px)rotate(135deg);
  }
}

@keyframes meteorStyle4 {
  from {
    opacity: 1;
    height: 1%;
    transform: translateY(0)translateX(0)rotate(115deg);
  }

  to {
    opacity: 1;
    height: 15%;
    transform: translateY(1260px)translateX(2560px)rotate(115deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

body {
  line-height: normal;
  font-family: var(--font-fslola-normal);
  color: #005e64;
  text-align: left;
  background: #005e64 radial-gradient(circle, #007780, #005e64) no-repeat;
  margin: 0;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
}

#video-background {
  min-width: 100%;
  min-height: 100%;
  z-index: -100;
  position: fixed;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

video#christmasVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

li, .group-main p {
  padding-bottom: .7rem;
}

.green-video, .video {
  max-width: 1200px;
  width: 95%;
  margin: 0 auto;
  display: block;
}

.green-icon {
  width: 80px;
  height: 1px;
  opacity: 0;
  margin: 0 auto 25px;
  padding-top: 60px;
  transition: all 2s ease-in-out;
  display: block;
}

.green-info {
  width: 95%;
  font-family: var(--font-fslola-italic);
  font-size: var(--font-size-base);
  color: var(--color-white);
  text-align: center;
  margin-bottom: 25px;
  padding-top: 25px;
}

.path-45-icon {
  height: calc(100% - 32px);
  width: 100%;
}

.group-div, .group-icon, .path-45-icon {
  bottom: 0;
  left: 0;
}

.group-icon {
  height: 100%;
  width: 100%;
}

.div, .sternschnuppenkunde-i {
  cursor: auto;
}

.accordion span, .btn-title {
  vertical-align: middle;
  height: 46px;
  display: table-cell;
}

.div {
  font-size: var(--font-size-sm);
  display: block;
}

.div1, .fnf-erstaunliche-fakten-ber {
  letter-spacing: -.69px;
  width: 15px;
  float: right;
  font-size: var(--font-size-title);
  font-family: var(--font-fslola-bold);
  margin-top: -8px;
  padding: 0 20px;
  transform: rotate(90deg);
}

.fnf-erstaunliche-fakten-ber {
  font-size: var(--font-size-base);
  letter-spacing: -.28px;
  font-weight: 700;
  display: block;
}

.die-sternschnuppe, .div2 {
  letter-spacing: -.69px;
  padding: 0 5px 0 10px;
  font-weight: 300;
  display: inline-block;
}

.die-sternschnuppe {
  font-size: var(--font-size-base);
  letter-spacing: -.28px;
  font-weight: 700;
  display: block;
}

.group-div1 > p, .group-main > p {
  font-size: var(--font-size-base);
}

.div3 {
  letter-spacing: -.69px;
  font-weight: 300;
  display: block;
}

.div3, .div4, .div5, .geminiden-2022 {
  display: inline-block;
}

.geminiden-2022 {
  font-size: var(--font-size-base);
  letter-spacing: -.28px;
  font-weight: 700;
  display: block;
}

.div5 {
  color: var(--color-white);
}

.group-div2 {
  width: 25.88px;
  height: 11px;
  font-size: var(--font-size-sm);
  display: block;
}

.ber-den-fotografen, .div6 {
  display: inline-block;
}

.ber-den-fotografen {
  font-size: var(--font-size-base);
  letter-spacing: -.28px;
  font-weight: 700;
  display: block;
}

.div6 {
  margin-top: 0;
  display: inline-block;
  transform: rotate(270deg);
}

.group-icon2 {
  width: 100%;
  display: block;
}

.weitere-infos-wwwmarkuseiche {
  font-size: .75rem !important;
}

.der-sternefotograf-markus, .panel p {
  font-size: var(--font-size-accordion);
  margin: 0;
}

.der-sternefotograf-markus-eich {
  width: 100%;
  letter-spacing: -.18px;
  font-size: 20px;
  display: inline-block;
}

.group-main {
  width: 100%;
  background-color: var(--color-white);
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-indigo);
  font-family: var(--font-fslola-italic);
  display: block;
}

.group-main a {
  color: var(--color-indigo);
  text-decoration: none;
}

.group-main a:hover {
  color: var(--color-indigo);
  text-decoration: underline;
}

.group-div1 {
  max-width: 866px;
  width: 75%;
  margin: 2rem auto 0;
  display: block;
  position: relative;
}

.web-2600-1-main {
  width: 100%;
  font-size: var(--font-size-lg);
  color: var(--color-indigo);
  font-family: var(--font-fslola-italic);
}

#after-video {
  opacity: 1;
  transition: all .8s ease-in-out;
}

.green-video.absolute {
  position: absolute;
}

.main-empty footer {
  width: 100%;
  z-index: 1000;
  background: url("berge_frei.2d4d095d.png") bottom / cover no-repeat;
  margin: 0 auto;
  overflow: hidden;
}

.footer__nav {
  padding-inline-start: 0;
}

.greeting-text {
  text-align: center;
  width: 100%;
  color: #fff;
}

.greeting-text p {
  max-width: 1200px;
  width: 95%;
  font-size: var(--font-size-base);
  text-align: left;
  margin-top: 40px;
  display: block;
}

footer * {
  font-family: var(--font-fslola-normal);
  list-style: none;
}

.footer__top--content {
  position: relative;
}

.content__headline {
  margin-top: 0;
  margin-bottom: 0;
}

.content__headline + [class*="content__"] {
  margin-top: 0;
}

.footer__row--top {
  padding: 0;
}

.footer__row--bottom {
  align-items: flex-end;
  padding-bottom: 10px;
}

.footer__col--claim {
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 15px;
}

span.claim__piece {
  font-family: var(--font-fslola-bold);
}

.claim {
  margin-bottom: 0;
}

.icon-collection__item a[target="_blank"] {
  background: none;
  padding: 0;
  line-height: .1;
}

.footer__col--claim .claim__piece {
  color: #fff;
  letter-spacing: 0;
  font-size: 22px;
}

.footer__col--logo-collection {
  flex-wrap: wrap;
  align-items: flex-end;
  display: flex;
}

.logo-collection__item {
  max-width: calc(50% - 40px);
  flex-basis: auto;
  margin: 15px;
}

.logo-collection__item img {
  height: auto;
  max-height: 70px;
  width: auto;
}

.icon-collection__item {
  float: left;
  margin: 0 30px 30px 0;
  padding-top: 48px;
  display: flex;
}

.icon-collection__item a img {
  height: auto;
  vertical-align: baseline;
  width: auto;
  transition: all .2s ease-in-out;
}

.footer__col--icon-collection {
  flex-wrap: wrap;
  align-items: flex-end;
  display: flex;
}

.video-collection__item {
  float: left;
  margin: 0 30px 30px 0;
  padding-top: 48px;
  display: flex;
}

.video-collection__item a[target="_blank"] {
  background: none;
  padding: 0;
  line-height: .1;
}

.video-collection__item a img {
  height: auto;
  vertical-align: baseline;
  width: auto;
  transition: all .2s ease-in-out;
}

.footer__wrapper {
  margin-top: 15%;
}

.footer__wrapper .container {
  width: 100%;
  margin: 0 auto;
}

p {
  margin: 0 auto;
}

.footer__nav--title {
  color: #fff;
  text-align: left;
  width: 100%;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18.263 18.263'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='1.6' d='M1.131 9.132l8 8 8-8'/%3E%3C/svg%3E");
  background-position: calc(100% - 20px) calc(50% - 3px);
  background-repeat: no-repeat;
  background-size: 19px 19px;
  border-bottom: 1px solid #fff;
  margin-bottom: 0;
  padding: 20px 20px 18px;
}

.footer__nav--sub {
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
}

.footer__nav--sub.open {
  display: block;
}

.footer__nav--sub-item {
  margin-bottom: 4px;
  padding-bottom: 0;
}

.footer__nav--sub-item a {
  letter-spacing: .02em;
  color: #fff;
  font-size: 16px;
  line-height: 1.3125;
  text-decoration: none;
}

.footer__nav--title, .teaser__title, .nav__call-to-action--title, .news-menu-view .item, .news-menu-view .item a {
  letter-spacing: .05em;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.375;
}

.accordion, .sternschnuppenkunde-i {
  color: #003e47;
  cursor: pointer;
  width: 100%;
  text-align: left;
  font-size: var(--font-size-base);
  font-family: var(--font-fslola-bold-italic);
  letter-spacing: -.28px;
  background-color: #fff;
  border: none;
  outline: none;
  margin: 20px 0 0;
  padding: 3px 3px 3px 21px;
  transition: all .4s;
  display: block;
}

.active, .accordion:hover {
  background-color: #fff;
}

.panel {
  height: auto;
  max-height: 0;
  background-color: #fff;
  padding: 0 21px;
  transition: max-height .5s ease-in-out;
  display: block;
  overflow: hidden;
}

.panel.active {
  max-height: 300vh;
}

.sternschnuppenkunde-i {
  font-family: var(--font-fslola-italic);
  letter-spacing: -.39px;
  cursor: auto;
  padding-left: 21px;
  font-size: var(--font-size-title) !important;
}

@media (min-width: 992px) {
  .footer__nav--title, .teaser__title, .nav__call-to-action--title, .news-menu-view .item, .news-menu-view .item a {
    letter-spacing: .05em;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.2942;
  }
}

.footer__nav--sub-item a:hover, .footer__nav--sub-item a:focus {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .footer__nav--title {
    background-image: none;
    border: 0;
    margin-bottom: 1em;
    padding: 0;
  }
}

@media (min-width: 1200px) {
  .icon-collection__item {
    padding-top: 0;
  }

  .footer__icon-collection--item:first-child {
    margin-left: 1px;
  }

  .footer__col--claim {
    justify-content: flex-start;
  }
}

img {
  max-width: 100%;
  vertical-align: middle;
  border-style: none;
}

.btn-play, .btn-replay, .btn-pause {
  cursor: pointer;
  z-index: 10000;
  width: 5%;
  position: absolute;
  left: 48.5%;
  right: 45.5%;
}

.btn-replay, .btn-pause {
  display: none;
}

video, .green-video, #mep_0 {
  transition: all .5s ease-in-out;
  overflow: hidden;
}

#mep_0 {
  z-index: 1000;
}

#after-video-slider {
  transition: all 1s ease-in-out;
  overflow: hidden;
}

.sliding-text {
  font-family: var(--font-fslola-normal);
  font-size: var(--font-size-normal);
  color: #fff;
  width: 50%;
  max-width: 1200pc;
  height: 30%;
  opacity: 0;
  padding: 20% 5% 5%;
  transition: all 1s ease-in-out;
  position: absolute;
  top: 30%;
  left: 25%;
  right: 25%;
  overflow: hidden;
}

body {
  overflow-x: hidden;
}

section {
  width: 100%;
  height: 100vh;
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
}

.sliding-div.video {
  font-family: var(--font-fslola-italic);
  font-size: var(--font-size-base);
  color: var(--color-white);
  padding-bottom: 1rem;
  transition: all 1s ease-in-out;
  overflow: hidden;
}

.sliding-div.video div {
  margin: 0 auto;
  text-align: left !important;
}

.dark {
  display: block;
  color: #fff !important;
  background: none !important;
}

ol, ul {
  padding-left: 18px;
}

@media (max-width: 1424px) {
  .container, .cookie__text, .cookie__row--navigation, .cookie__banner--title, .cookie__banner--description, .cookie__banner--list-groups, .cookie__banner--note, .news, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1344px;
  }
}

@media (max-width: 1200px) {
  .container, .cookie__text, .cookie__row--navigation, .cookie__banner--title, .cookie__banner--description, .cookie__banner--list-groups, .cookie__banner--note, .news, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

@media (max-width: 992px) {
  .container, .cookie__text, .cookie__row--navigation, .cookie__banner--title, .cookie__banner--description, .cookie__banner--list-groups, .cookie__banner--note, .news, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (max-width: 768px) {
  .container, .cookie__text, .cookie__row--navigation, .cookie__banner--title, .cookie__banner--description, .cookie__banner--list-groups, .cookie__banner--note, .news, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (max-width: 575.98px) {
  .container, .cookie__text, .cookie__row--navigation, .cookie__banner--title, .cookie__banner--description, .cookie__banner--list-groups, .cookie__banner--note, .news, .container-sm {
    max-width: 540px;
  }

  .sternschnuppenkunde-i {
    font-size: var(--font-size-base) !important;
  }

  .group-div1 > p, .group-main > p {
    font-size: 1rem;
  }

  .accordion, .sternschnuppenkunde-i {
    font-size: var(--font-size-normal);
    padding-left: 9px;
  }

  .group-div1 {
    width: 95%;
    transition: all .8s ease-in-out;
  }

  .group-main {
    width: 100%;
    padding: 5px;
  }

  .panel {
    padding: 0 4px;
  }

  ol, ul {
    padding-left: 15px;
  }

  .weitere-infos-wwwmarkuseiche {
    font-size: var(--font-size-sm);
  }

  .web-2600-1-main, .footer__wrapper {
    padding-bottom: 5%;
  }

  .sliding-text {
    width: 90%;
    height: 19%;
    top: 38%;
    left: 5%;
    right: 5%;
  }

  .sliding-div.video {
    font-size: var(--font-size-normal);
    padding: 0;
  }

  .greeting-text p {
    font-size: var(--font-size-normal);
    text-align: center;
  }

  .der-sternefotograf-markus, .panel p {
    font-size: var(--font-size-normal);
  }

  footer .footer__logo, footer p {
    text-align: center;
  }

  .row > [class*="col-"]:not(.footer__col--nav) {
    padding-left: 10px;
    padding-right: 10px;
  }

  .green-info {
    font-size: var(--font-size-mobile);
  }

  .greeting-text p {
    width: 95%;
  }

  .icon-collection__item {
    padding-top: 10px;
  }

  .footer__col.footer__col--top-right {
    margin: 50px 0;
  }

  .green-video, .video {
    padding: 0 .5rem;
  }
}

@media (max-width: 767.98px) {
  body {
    letter-spacing: .02em;
    font-size: 18px;
    line-height: 1.4445;
  }

  .footer__nav--sub-item a {
    padding: 10px 20px;
    display: block;
  }

  .footer__col--icon-collection {
    justify-content: space-around;
  }
}

@media (min-width: 768px) {
  .footer__nav--sub {
    display: block;
  }

  .logo-collection__item {
    max-width: calc(25% - 40px);
  }
}

@media (max-width: 1199.98px) {
  .footer__col--logo-collection {
    place-content: space-evenly center;
    padding: 0;
    display: none;
  }

  .footer__wrapper {
    margin-top: 10%;
  }

  .btn-play, .btn-replay, .btn-pause {
    margin-top: 27vw;
  }

  .footer__col--icon-collection {
    justify-content: center;
  }
}

@media (min-width: 1200px) {
  .btn-play, .btn-replay, .btn-pause {
    width: 120px;
    margin-top: 310px;
  }

  .logo-collection__item {
    flex-basis: calc(25% - 40px);
    margin: 0 40px 30px 0;
  }
}

/*# sourceMappingURL=index.182c1ea4.css.map */
